<script>
	import Base from '@backend/Base.vue';

	export default {
		name: "AppCampaign",
		extends: Base,
		data() {
			return {
				Name: "AppCampaign",
				mrGroup: [],
				mrCampaignType: {
					'1': 'Every Visit Page',
					'2': 'Before Submit',
					'3': 'Interval',
				},
				mrType: {
					'alpha': 'Alpha',
					'numeric': 'Numeric',
					'alphanumeric': 'Alpha Numeric',
					'currency': 'Currency',
					'email': 'Email',
					'phone': 'Phone',
					'textarea': 'Text Area'
				},
				bgType: 'solidColor'
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.status'() {
				this.search()
			},
			'row.ac_title_type'() {
				if (!this.$route.params.id) return
				this.$set(this.row, 'ac_title', '')
			},
			'row.ac_title_image_type'() {
				if (!this.$route.params.id) return
				this.$set(this.row, 'ac_title', '')
			},
			'filter.start_date'(v) {
				if (!v || !this.filter.end_date) return;
				this.search()
			},
			'filter.end_date'(v) {
				if (!v || !this.filter.start_date) return;
				if (this.filter.start_date > v) return swal('Filter', 'The start date is greater than the end date.',
					'warning')
				if (!this.$route.params.id) this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-2">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-md-2">
									<DatePicker v-model="filter.start_date" placeholder="Start Date"></DatePicker>
								</div>
								<div class="col-md-2">
									<DatePicker v-model="filter.end_date" placeholder="End Date"></DatePicker>
								</div>
								<div class="col-sm-2">
									<select2 :options="{Y:'Active',N:'Inactive'}" v-model="filter.status">
										<option value="">All Status</option>
									</select2>
								</div>
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" v-on:keyup.enter="search"
												class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="ac_title"></SortField>
									</th>
									<!-- <th>Group</th> -->
									<!-- <th>{{fields.ac_url}}</th> -->
									<th>{{fields.ac_tags}}</th>
									<th>{{fields.ac_start_date}}</th>
									<th>{{fields.ac_end_date}}</th>
									<th>Status</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td v-if="v.ac_title_type=='image'">
										<ImagePreview v-if="v.ac_title" :src="uploader(v.ac_title)"></ImagePreview>
										<span v-else>-</span>
									</td>
									<td v-else>{{(v.ac_title||"-").limitChar(50)}}</td>
									<!-- <td>{{(v.acg_name||"").limitChar(30)}}</td> -->
									<!-- <td>{{v.ac_url}}</td> -->
									<td>
										<template v-for="(v2,k2) in v.ac_tags">
											<span v-if="v2" :key="k2" class="label label-info">{{v2}}</span>
											<span v-else :key="k2">-</span>
										</template>

									</td>
									<td>{{(v.ac_start_date||"").dates("format")}}</td>
									<td>{{(v.ac_end_date||"").dates("format")}}</td>
									<td>
										<StatusLabel :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
										<a href="javascript:;" class="icon_action" @click="changeStatus(k)"
											v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
											v-tooltip="'Remove'"><i class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<div class="panel_group">
								<div class="panel_heading">Dialog Box Style</div>
								<div class="panel_body">
									<BoField name="ac_is_background">
										<div class="row">
											<radio name="ac_is_background" v-model="row.ac_is_background" option="Y"
												class="col-lg-auto">Yes
											</radio>
											<radio name="ac_is_background" v-model="row.ac_is_background" option="N"
												class="col-lg-auto">No
											</radio>
										</div>
									</BoField>
									<div class="row" v-if="row.ac_is_background=='N'">
										<div class="col-md-4">
											<BoField :attr="{type:'color'}" v-model="row.ac_background_color"
												label="Background Color">
											</BoField>
										</div>
										<div class="col-md-4">
											<BoField label="Background Opacity" v-model="row.ac_background_opacity"
												:attr="{type:'Number',min:0}">
											</BoField>
										</div>
									</div>
									<div class="row" v-else>
										<div class="col-md-4">
											<BoField name="ac_image_overlay" v-if="row.ac_is_background=='Y'">
												<div class="row">
													<radio name="ac_image_overlay" class="col-lg-auto"
														v-model="row.ac_image_overlay" option="Y">Yes
													</radio>
													<radio name="ac_image_overlay" class="col-lg-auto"
														v-model="row.ac_image_overlay" option="N">No
													</radio>
												</div>
											</BoField>
										</div>
										<div class="col-md-4" v-if="row.ac_image_overlay=='Y'">
											<BoField label="Background Opacity" v-model="row.ac_background_opacity"
												:attr="{type:'Number',min:0}">
											</BoField>
										</div>
									</div>
									<BoField name="ac_image" v-if="row.ac_is_background=='Y'" class="mb-0">
										<Uploader name="ac_image" type="background" uploadType="cropping"
											:param="{thumbnail:true}" v-model="row.ac_image"></Uploader>
									</BoField>
								</div>
							</div>
						</div>
						<div class="col-sm-6">

							<div class="panel_group">
								<div class="panel_heading">Title</div>
								<div class="panel_body">
									<div class="row">
										<div class="col-md-12">
											<BoField name="" label="Title Type" class="mb-0">
												<div class="row">
													<radio name="ac_title_type" class="col-lg-auto"
														v-model="row.ac_title_type" option="text">Text
													</radio>
													<radio name="ac_title_type" class="col-lg-auto"
														v-model="row.ac_title_type" option="image">
														Image</radio>
												</div>
											</BoField>
										</div>
										<div class="col-md-12" v-if="row.ac_title_type=='image'">
											<BoField name="" label="Title Type" class="mb-0">
												<div class="row">
													<div class="col-md-2 pl-0">
														<radio name="ac_title_image_type"
															v-model="row.ac_title_image_type" option="logo">Logo
														</radio>
													</div>
													<div class="col-md-2">
														<radio name="ac_title_image_type"
															v-model="row.ac_title_image_type" option="banner">Banner
														</radio>
													</div>
												</div>
											</BoField>
										</div>
										<template v-if="row.ac_title_type=='image'">
											<div class="col-md-6" v-if="row.ac_title_image_type=='logo'">
												<BoField name="ac_title">
													<Uploader name="ac_title" type="logo"
														v-bind="validation('ac_title')" uploadType="cropping"
														:param="{thumbnail:true}" v-model="row.ac_title"></Uploader>
												</BoField>
											</div>
											<div class="col-md-12" v-if="row.ac_title_image_type=='banner'">
												<BoField name="ac_title">
													<Uploader name="ac_title" type="banner_campaign"
														v-bind="validation('ac_title')" uploadType="cropping"
														:param="{thumbnail:true}" v-model="row.ac_title"></Uploader>
												</BoField>
											</div>
										</template>
										<div class="col-md-12" v-else>
											<BoField name="ac_title">
												<textarea name="ac_title" v-bind="validation('ac_title')"
													class="form-control" v-model="row.ac_title"
													:placeholder="fields.ac_title"></textarea>
											</BoField>
										</div>
										<div class="col-md-6" v-if="row.ac_title_type=='text'">
											<BoField name="ac_title_style">
												<select2 name="ac_title_style" v-bind="{required:true}"
													v-model="row.ac_title_style"
													:options="{'Avian_bold':'Avian Bold','HolidayFree':'Holiday Free','Poppins':'Poppins','Trendsetter':'Trendsetter','Abuget':'Abuget','Free Pen':'Free Pen','Rembank':'Rembank','Paul Signature':'Paul Signature'}">
													<!-- <option value="">Select Font Style</option> -->
												</select2>
											</BoField>
										</div>
										<div class="col-md-3" v-if="row.ac_title_type=='text'">
											<BoField :attr="{type:'color'}" name="ac_title_color"
												v-model="row.ac_title_color"></BoField>
										</div>
										<div class="col-md-3" v-if="row.ac_title_type=='text'">
											<div class="form-group">
												<label for="">Size</label>
												<select v-model="row.ac_title_size" name="" id="" class="form-control">
													<option value="24">24</option>
													<option value="28">28</option>
													<option value="32">32</option>
													<option value="36">36</option>
													<option value="40">40</option>
													<option value="44">44</option>
													<option value="48">48</option>
												</select>
											</div>
										</div>
										<div class="col-12" v-if="row.ac_title_type=='text'">
											<div class="form-group mb-0">
												<label for="">Style Preview</label>
												<h3 :style="'font-family:'+row.ac_title_style+';font-size:'+row.ac_title_size+'px;'+(row.ac_title_style=='Poppins' ? 'font-weight:600;':'')+'color:'+row.ac_title_color"
													class="bg-dark py-1 px-1">{{row.ac_title}}</h3>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="panel_group">
								<div class="panel_heading">Sub Title</div>
								<div class="panel_body">
									<div class="row">
										<div class="col-md-12">
											<BoField name="ac_sub_title" label="">
												<textarea name="ac_sub_title" placeholder="Sub Title"
													v-bind="validation('ac_sub_title')" class="form-control"
													v-model="row.ac_sub_title"></textarea>
											</BoField>
										</div>
										<div class="col-md-6">
											<BoField name="ac_sub_title_style">
												<select2 name="ac_sub_title_style" v-bind="{required:true}"
													v-model="row.ac_sub_title_style"
													:options="{'Avian_bold':'Avian Bold','HolidayFree':'Holiday Free','Poppins':'Poppins','Trendsetter':'Trendsetter','Abuget':'Abuget','Free Pen':'Free Pen','Rembank':'Rembank','Paul Signature':'Paul Signature'}">
													<!-- <option value="">Select Font Style</option> -->
												</select2>
											</BoField>
										</div>
										<div class="col-md-3">
											<BoField :attr="{type:'color'}" name="ac_sub_title_color"
												v-model="row.ac_sub_title_color">
											</BoField>
										</div>
										<div class="col-md-3">
											<div class="form-group">
												<label for="">Size</label>
												<select v-model="row.ac_sub_title_size" name="" id=""
													class="form-control">
													<option value="18">18</option>
													<option value="22">22</option>
													<option value="28">28</option>
													<option value="32">32</option>
													<option value="36">36</option>
												</select>
											</div>
										</div>
										<div class="col-12">
											<div class="form-group mb-0">
												<label for="">Style Preview</label>
												<h3 :style="'font-family:'+row.ac_sub_title_style+';font-size:'+row.ac_sub_title_size+'px;'+(row.ac_sub_title_style=='Poppins' ? 'font-weight:600;':'')+'color:'+row.ac_sub_title_color"
													class="bg-dark py-1 px-1">{{row.ac_sub_title}}</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-12">

							<div class="panel_group mb-3">
								<div class="panel_heading">Description</div>
								<div class="panel_body">
									<div class="row">
										<div class="col-md-12">
											<BoField name="ac_description" label="Campaign Description">
												<CKEditor name="ac_description" v-bind="validation('ac_description')"
													v-model="row.ac_description"> </CKEditor>
											</BoField>
										</div>
										<div class="col-md-3">
											<BoField :attr="{type:'color'}" name="ac_description_color"
												v-model="row.ac_description_color">
											</BoField>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="panel_group">
						<div class="panel_heading">Campaign Field</div>
						<div class="panel_body">
							<BoField name="" label="Show Input Field?" class="mb-0">
								<div class="row">
									<radio name="ac_is_form" class="col-lg-auto" v-model="row.ac_is_form" option="Y">Yes
									</radio>
									<radio name="ac_is_form" class="col-lg-auto" v-model="row.ac_is_form" option="N">No
									</radio>
								</div>
							</BoField>
							<template v-if="row.ac_is_form=='Y'">
								<BoField name="ac_form_contrast" label="Field Contrast Mode">
									<div class="row">
										<radio name="ac_form_contrast" v-model="row.ac_form_contrast"
											class="col-lg-auto" option="light"> Light
											Border</radio>
										<radio name="ac_form_contrast" v-model="row.ac_form_contrast"
											class="col-lg-auto" option="dark">Dark
											Border</radio>
										<radio name="ac_form_contrast" v-model="row.ac_form_contrast"
											class="col-lg-auto" option="white-bg">
											White Background</radio>
									</div>
								</BoField>
								<div class="row">
									<div class="col-md-4 pb-3" v-for="(v2,k2) in row.ac_form" :key="k2">
										<div class="media m-b-10 h-100">
											<div
												class="d-flex align-items-center justify-content-between border-bottom pb-2">
												<h3 class="border-0 py-2 mb-0">Field #{{k2+1}}</h3>
												<a href="javascript:;" v-if="row.ac_form.length>1&&v2.delete!='0'"
													v-tooltip="'Delete'" @click="row.ac_form.splice(k2,1)"
													class="btn btn-circle btn-danger btn-sm" style="float:right"><i
														class="fa fa-trash"></i></a>
											</div>
											<BoField :name="'name'+k2" v-model="v2.name"
												:attr="{placeholder:'Field',readonly:v2.delete=='0'}">
											</BoField>
											<BoField :name="'type'+k2" v-model="v2.type" :labelText="false">
												<select2 v-model="v2.type" :disabled="v2.delete=='0'" :options="mrType">
													<option value="">--Select Input Type--</option>
												</select2>
											</BoField>
											<BoField :name="'required'+k2" :labelText="false">
												<div v-if="v2.delete!='0'">
													<radio :name="'required'+k2" v-model="v2.required"
														class="pl-0 col-md-auto d-inline-block" option="Y">Required
													</radio>
													<radio :name="'required'+k2" v-model="v2.required"
														class="col-md-6 d-inline-block" option="N">
														Not Required </radio>
												</div>
												<span v-else class="badge badge-danger">Required</span>
											</BoField>
										</div>
									</div>
									<div class="col-md-4">
										<a href="javascript:;" class="btn btn-outline-success"
											@click="row.ac_form.push({required:'Y'})"><i class="fa fa-plus mr-2"></i>Add
											Field</a>
									</div>
								</div>
								<div class="row">
									<div class="col-md-5">
										<BoField name="ac_button_label" mandatory :attr="{required:true}"
											v-model="row.ac_button_label"> </BoField>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="panel_group" v-if="row.ac_is_form=='Y'">
						<div class="panel_heading">Response Mail</div>
						<div class="panel_body">
							<BoField name="ac_send_email">
								<div class="row">
									<radio name="ac_send_email" class="col-lg-auto" v-model="row.ac_send_email"
										option="Y">Yes</radio>
									<radio name="ac_send_email" class="col-lg-auto" v-model="row.ac_send_email"
										option="N">No</radio>
								</div>
							</BoField>
							<template v-if="row.ac_send_email=='Y'">
								<BoField name="ac_subject_email" mandatory :attr="{required:true,maxlength:150}"
									v-model="row.ac_subject_email">
								</BoField>
								<BoField name="ac_body_email" mandatory :attr="{required:true}">
									<div class="alert alert-info">:name: adalah template untuk memanggil nama subscriber
									</div>
									<CKEditor name="ac_body_email" v-model="row.ac_body_email"
										v-bind="validation('ac_body_email')">
									</CKEditor>
								</BoField>
							</template>
						</div>
					</div>
					<div class="panel_group">
						<div class="panel_heading">Campaign Settings</div>
						<div class="panel_body">
							<div class="row m-b-10">
								<div class="col-md-6">
									<div class="row">
										<div class="col-md-6">
											<BoField name="ac_start_date">
												<DatePicker name="ac_start_date" v-bind="validation('ac_start_date')"
													v-model="row.ac_start_date" placeholder="Start Date"></DatePicker>
											</BoField>
										</div>
										<div class="col-md-6">
											<BoField name="ac_end_date">
												<DatePicker name="ac_end_date" v-bind="validation('ac_end_date')"
													v-model="row.ac_end_date" placeholder="End Date"></DatePicker>
											</BoField>
										</div>
									</div>
									<BoField name="ac_url">
										<div class="row" v-for="(v,k) in row.ac_url" :key="k">
											<div class="col-md-9 m-b-10">
												<input type="text" class="form-control" v-model="v.value">
											</div>
											<div class="col-md-3">
												<button class="btn btn-success m-r-10"
													@click="row.ac_url.push({value:''})" type="button"><i
														class="fas fa-plus"></i></button>
												<button v-if="row.ac_url.length>1" class="btn btn-danger"
													@click="row.ac_url.splice(k,1)" type="button"><i
														class="fas fa-minus"></i></button>
											</div>
										</div>
									</BoField>
									<BoField name="status">
										<div class="row">
											<radio name="status" class="col-lg-auto" v-model="row.status" option="Y">
												Active</radio>
											<radio name="status" class="col-lg-auto" v-model="row.status" option="N">
												Inactive</radio>
										</div>
									</BoField>
								</div>
								<div class="col-md-6">
									<div class="row">
										<div class="col-md-8">
											<BoField name="ac_type">
												<select2 v-model="row.ac_type">
													<option value="1">Every Visit Page</option>
													<option value="2" v-if="row.ac_is_form=='Y'">Before Submit</option>
													<option value="3">Interval</option>
												</select2>
											</BoField>
										</div>
										<div class="col-md-4">
											<BoField v-if="row.ac_type=='3'" name="ac_interval"
												v-model="row.ac_interval" mandatory :attr="{type:'Number',min:0,required:true}">
											</BoField>
										</div>
									</div>
									<BoField name="ac_tags" mandatory v-if="row.ac_is_form=='Y'">
										<TagsInput name="ac_tags" v-model="row.ac_tags" :attr="{required:true}">
										</TagsInput>
									</BoField>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<hr>
							<div class="text-right">
								<a href="javascript:;" data-toggle="modal" data-target="#onbPreview"
									class="btn-rounded fcbtn btn btn-success btn-outline btn-1e mr-2">Preview Campaign
									<i class="icon-arrow-right14 position-right"></i></a>
								<button type="submit"
									class="btn-rounded fcbtn btn btn-info btn-outline btn-1e btn-loading">Save
									Campaign
									<i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
		<div id="onbPreview" class="modal fade">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Preview</h5>
						<button type="button" class="close" data-dismiss="modal">×</button>
					</div>

					<div class="modal-body">
						<div :class="'campComp '+(row.ac_is_background=='Y' ? row.ac_image_overlay=='Y'?'campComp--wOverlay':'' : 'campComp--wBgColor')"
							:style="(row.ac_is_background=='Y'?'background-image:url('+uploader(row.ac_image)+');'+(row.ac_image_overlay=='Y'?';--bgOpacity:'+row.ac_background_opacity/100:''):'--bgColor:'+row.ac_background_color+';--bgOpacity:'+row.ac_background_opacity/100)">
							<div class="modCampaign">
								<div class="modCampaign__title">
									<template v-if="row.ac_title_type=='image'">
										<div class="modCampaign__banner" v-if="row.ac_title_image_type=='banner'">
											<img :src="row.ac_title ? uploader(row.ac_title):assets('fo_images','campaign-banner-v2.png')"
												alt="">
										</div>
										<img v-else :src="uploader(row.ac_title)" />
									</template>
									<h3 v-else
										:style="'color:'+row.ac_title_color+';font-family:'+row.ac_title_style+';font-size:'+row.ac_title_size+'px;'+(row.ac_title_style=='Poppins' ? 'font-weight:600;':'')">
										{{row.ac_title}}</h3>
									<span class="modCampaign__subheading"
										:style="'color:'+row.ac_sub_title_color+';font-family:'+row.ac_sub_title_style+';font-size:'+row.ac_sub_title_size+'px;'+(row.ac_sub_title_style=='Poppins' ? 'font-weight:600;':'')">{{row.ac_sub_title}}</span>
								</div>
								<div class="modCampaign__desc" :style="'color:'+row.ac_description_color+';'"
									v-html="row.ac_description">
								</div>
								<div v-if="row.ac_is_form=='Y'"
									:class="'modCampaign__form '+(row.ac_form_contrast=='white-bg'?'modCampaign__form--wInputBg':row.ac_form_contrast=='light'?'modCampaign__form--lightField':'')">
									<div class="row justify-content-center">
										<div class="col-md-8">
											<div class="form-group" v-for="(v,k) in row.ac_form" :key="k">
												<textarea v-if="v.type=='textarea'" :required="v.required=='Y'" rows="4"
													v-model="v.value" :name="v.field" class="frm_custom_sign h-auto"
													:placeholder="v.name"></textarea>

												<input v-else-if="v.type=='alpha'" onkeydown="return alphaOnly(event)"
													type="text" :required="v.required=='Y'" v-model="v.value"
													:name="v.field" class="frm_custom_sign" :placeholder="v.name">

												<input v-else-if="v.type=='numeric'" onkeydown="return numberKey(event)"
													type="number" :required="v.required=='Y'" v-model="v.value"
													:name="v.field" class="frm_custom_sign" :placeholder="v.name">

												<input v-else-if="v.type=='alphanumeric'"
													onkeydown="return alphaNumber(event)" type="text"
													:required="v.required=='Y'" v-model="v.value" :name="v.field"
													class="frm_custom_sign" :placeholder="v.name">

												<input v-else-if="v.type=='email'" onkeydown="return emailKey(event)"
													:type="v.type" :required="v.required=='Y'" v-model="v.value"
													:name="v.field" class="frm_custom_sign" :placeholder="v.name">

												<input v-else-if="v.type=='phone'" onkeydown="return mobileKey(event)"
													type="text" :required="v.required=='Y'" v-model="v.value"
													:name="v.field" class="frm_custom_sign" :placeholder="v.name">

												<InputCurrency v-else-if="v.type=='currency'" :name="v.field"
													class="frm_custom_sign" v-model="v.value"
													:required="v.required=='Y'" :placeholder="v.name">
												</InputCurrency>

												<input v-else :type="v.type" :required="v.required=='Y'"
													v-model="v.value" :name="v.field" class="frm_custom_sign"
													:placeholder="v.name">

												<LabelError :name="v.field"></LabelError>
											</div>
										</div>
									</div>
									<p class="mb-0 text-center">
										<button type="submit"
											class="btn_line btn-loading">{{row.ac_button_label || 'Campaign Button Label'}}</button>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<button type="button" class="btn" data-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>